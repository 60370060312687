import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function TopProducts(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80.712"
      height="80.712"
      viewBox="0 0 80.712 80.712">
      <g data-name="Group 3315">
        <g data-name="Group 2995">
          <g data-name="Ellipse 28-4">
            <path
              fill="#fff"
              d="M40.356 0A40.356 40.356 0 110 40.356 40.356 40.356 0 0140.356 0z"
              data-name="Path 2615"></path>
          </g>
        </g>
        <g data-name="Group 3059" transform="translate(19.856 33.485)">
          <path
            fill="#fff"
            d="M1.684 0h35.534A1.684 1.684 0 0138.9 1.684V28.2a1.684 1.684 0 01-1.684 1.684H1.684A1.684 1.684 0 010 28.2V1.684A1.684 1.684 0 011.684 0z"
            data-name="Path 2616"
            transform="translate(1.05 1.05)"></path>
          <path
            fill="#3b49d3"
            d="M877.812 106.43h-36.6a2.2 2.2 0 01-2.2-2.2V76.647a2.2 2.2 0 012.2-2.2h36.6a2.2 2.2 0 012.2 2.2v27.582a2.2 2.2 0 01-2.2 2.201zm-36.6-29.883a.1.1 0 00-.1.1v27.582a.1.1 0 00.1.1h36.6a.105.105 0 00.1-.1V76.647a.106.106 0 00-.1-.1z"
            data-name="Path 2576"
            transform="translate(-839.011 -74.447)"></path>
        </g>
        <g data-name="Group 3060" transform="translate(19.856 44.029)">
          <path
            fill="#3b49d3"
            d="M878.963 89.1h-38.9a1.05 1.05 0 010-2.1h38.9a1.05 1.05 0 010 2.1z"
            data-name="Path 2577"
            transform="translate(-839.011 -87.003)"></path>
        </g>
        <g data-name="Group 3061" transform="translate(35.738 39.285)">
          <path
            fill="#fff"
            d="M0 0H7.048V11.386H0z"
            data-name="Rectangle 1713"
            transform="translate(1.05 1.05)"></path>
          <path
            fill="#3b49d3"
            d="M866.02 94.84h-7.049a1.05 1.05 0 01-1.05-1.05V82.4a1.05 1.05 0 011.05-1.05h7.049a1.05 1.05 0 011.05 1.05v11.39a1.05 1.05 0 01-1.05 1.05zm-6-2.1h4.949v-9.286h-4.949z"
            data-name="Path 2578"
            transform="translate(-857.921 -81.354)"></path>
        </g>
        <g data-name="Group 3062" transform="translate(24.001 58.819)">
          <path
            fill="#3b49d3"
            d="M864.949 106.712H845a1.05 1.05 0 110-2.1h19.952a1.05 1.05 0 110 2.1z"
            data-name="Path 2579"
            transform="translate(-843.947 -104.612)"></path>
        </g>
        <g data-name="Group 3063" transform="translate(24.001 53.978)">
          <path
            fill="#3b49d3"
            d="M850.865 100.948H845a1.05 1.05 0 110-2.1h5.868a1.05 1.05 0 010 2.1z"
            data-name="Path 2580"
            transform="translate(-843.947 -98.848)"></path>
        </g>
        <g data-name="Group 3064" transform="translate(24.001 49.182)">
          <path
            fill="#3b49d3"
            d="M847.931 95.238H845a1.05 1.05 0 110-2.1h2.934a1.05 1.05 0 010 2.1z"
            data-name="Path 2581"
            transform="translate(-843.947 -93.138)"></path>
        </g>
        <g data-name="Group 3065" transform="translate(29.802 8.542)">
          <path
            fill="#3b49d3"
            d="M855.534 64.927A1.049 1.049 0 01854.5 63.7l1.029-6-4.356-4.246a1.051 1.051 0 01.581-1.791l6.021-.875 2.693-5.456a1.05 1.05 0 011.883 0l2.693 5.456 6.021.875a1.051 1.051 0 01.582 1.791l-4.359 4.246 1.029 6a1.05 1.05 0 01-1.524 1.107l-5.386-2.831-5.385 2.831a1.051 1.051 0 01-.488.12zm5.874-5.187a1.055 1.055 0 01.489.12l3.991 2.1-.762-4.445a1.051 1.051 0 01.3-.929l3.228-3.146-4.462-.649a1.052 1.052 0 01-.791-.574l-2-4.044-2 4.044a1.05 1.05 0 01-.79.574l-4.461.649 3.228 3.146a1.051 1.051 0 01.3.929l-.764 4.445 3.991-2.1a1.059 1.059 0 01.503-.12z"
            data-name="Path 2582"
            transform="translate(-850.854 -44.749)"></path>
        </g>
      </g>
    </svg>
  );
}
