import { StatisticsProductResponse } from '@b7hio/api-lib/src/ops-portal';
import BlockOutlined from '@mui/icons-material/BlockOutlined';
import { Box, Divider, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { OverviewComponents } from '../Overview/Overview';

interface TopProductsListItem {
  readonly name: string;
  readonly timePeriod: string;
  readonly depositValue: number;
  readonly circleColor: string;
}

const TopProductsListItem = ({
  name,
  timePeriod,
  depositValue,
  circleColor,
}: TopProductsListItem): JSX.Element => {
  const { t } = useTranslation(['common']);
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'max-content 1fr',
          alignItems: 'center',
          gap: 2,
          p: 1,
        }}>
        <Box
          sx={{
            width: '50px',
            height: '50px',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            borderRadius: '25%',
            background: circleColor,
            fontWeight: '800',
          }}>
          {timePeriod}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'right',
          }}>
          <Typography
            variant="body1"
            sx={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}>
            {name}
          </Typography>
          <Box
            sx={{
              textAlign: 'right',
              color: theme.palette.primary.main,
            }}>
            {t('common:currency', {
              value: {
                amount: depositValue,
                currency: 'GBP',
              },
            })}
          </Box>
        </Box>
      </Box>
    </>
  );
};

interface TopProductsChartProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly logo: any;
  readonly header: string;
  readonly subHeader: string;
  readonly topTimePeriods: readonly string[];
  readonly data: readonly StatisticsProductResponse[];
}

export const TopProductsChart = ({
  logo,
  header,
  subHeader,
  topTimePeriods,
  data,
}: TopProductsChartProps): JSX.Element => {
  const circleColors = ['#F0F2FC', '#EBEDFF', '#DFE2FC', '#CCD2FF', '#B5BCFC'];
  const topFive = data.slice(0, 5);

  return (
    <>
      <OverviewComponents
        logo={logo}
        header={header}
        subHeader={subHeader}
        isTopChartHeader={true}
      />
      <Divider />
      {topFive.length > 0 ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            flexGrow: 1,
            justifyContent: 'space-between',
            py: 1,
          }}>
          {topFive.map((props, idx) => (
            <React.Fragment key={props.name}>
              {idx !== 0 && <Divider />}
              <TopProductsListItem
                circleColor={circleColors[idx]}
                timePeriod={topTimePeriods[idx]}
                depositValue={props.deposit}
                name={props.name}
              />
            </React.Fragment>
          ))}
        </Box>
      ) : (
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <BlockOutlined color="disabled" />
        </Box>
      )}
    </>
  );
};
