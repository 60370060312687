import React from 'react';

export function TopPlatforms() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80.712"
      height="80.712"
      viewBox="0 0 80.712 80.712">
      <g data-name="Group 3314">
        <g data-name="Group 2995">
          <g data-name="Ellipse 28-4">
            <ellipse
              cx="40.356"
              cy="40.356"
              fill="#fff"
              data-name="Ellipse 31"
              rx="40.356"
              ry="40.356"></ellipse>
          </g>
        </g>
        <g data-name="Group 3049" transform="translate(15.37 18.761)">
          <path
            fill="#374adb"
            d="M772.438 90.063h-42.527a4.118 4.118 0 01-4.111-4.113V58.065a4.119 4.119 0 014.114-4.114h42.527a4.118 4.118 0 014.113 4.114V85.95a4.118 4.118 0 01-4.116 4.113zm-42.527-34.012a2.016 2.016 0 00-2.014 2.014V85.95a2.016 2.016 0 002.014 2.013h42.527a2.015 2.015 0 002.013-2.013V58.065a2.015 2.015 0 00-2.013-2.014z"
            data-name="Path 2567"
            transform="translate(-725.797 -53.951)"></path>
        </g>
        <g data-name="Group 3050" transform="translate(45.663 25.721)">
          <path
            fill="#374adb"
            d="M774.111 64.339h-11.193a1.05 1.05 0 010-2.1h11.194a1.05 1.05 0 110 2.1z"
            data-name="Path 2568"
            transform="translate(-761.868 -62.239)"></path>
        </g>
        <g data-name="Group 3051" transform="translate(45.663 32.448)">
          <path
            fill="#374adb"
            d="M774.111 72.348h-11.193a1.05 1.05 0 010-2.1h11.194a1.05 1.05 0 110 2.1z"
            data-name="Path 2569"
            transform="translate(-761.868 -70.248)"></path>
        </g>
        <g data-name="Group 3052" transform="translate(45.663 39.175)">
          <path
            fill="#374adb"
            d="M774.111 80.358h-11.193a1.05 1.05 0 010-2.1h11.194a1.05 1.05 0 110 2.1z"
            data-name="Path 2570"
            transform="translate(-761.868 -78.258)"></path>
        </g>
        <g data-name="Group 3053" transform="translate(30.865 58.022)">
          <path
            fill="#3b49d3"
            d="M760.717 107.495h-13.073a3.4 3.4 0 010-6.8h13.072a3.4 3.4 0 110 6.8zm-13.072-4.7a1.3 1.3 0 000 2.6h13.072a1.3 1.3 0 100-2.6z"
            data-name="Path 2571"
            transform="translate(-744.247 -100.698)"></path>
        </g>
        <g data-name="Group 3054" transform="translate(34.517 53.474)">
          <path
            fill="#3b49d3"
            d="M0 0H5.233V2.099H0z"
            data-name="Rectangle 1710"
            transform="rotate(-70.602 3.486 2.468)"></path>
        </g>
        <g data-name="Group 3055" transform="translate(43.517 53.474)">
          <path
            fill="#3b49d3"
            d="M0 0H2.099V5.233H0z"
            data-name="Rectangle 1711"
            transform="rotate(-19.406 2.038 .348)"></path>
        </g>
        <g data-name="Group 3056" transform="translate(39.44 48.526)">
          <path
            fill="#3b49d3"
            d="M755.768 92.011a1.309 1.309 0 111.31-1.31 1.311 1.311 0 01-1.31 1.31zm0-1.68a.37.37 0 10.37.37.37.37 0 00-.37-.37z"
            data-name="Path 2572"
            transform="translate(-754.458 -89.392)"></path>
        </g>
        <g data-name="Group 3057" transform="translate(20.925 22.876)">
          <path
            fill="#fff"
            d="M743.074 60.1l2.908 5.893 6.5.945-4.706 4.587L748.891 78l-5.817-3.058L737.257 78l1.111-6.477-4.706-4.587 6.5-.945z"
            data-name="Path 2573"
            transform="translate(-732.612 -59.051)"></path>
          <path
            fill="#3b49d3"
            d="M748.69 78.853a1.055 1.055 0 01-.489-.121l-5.327-2.8-5.328 2.8a1.05 1.05 0 01-1.524-1.106l1.018-5.933-4.31-4.2a1.05 1.05 0 01.581-1.791l5.958-.865 2.664-5.4a1.05 1.05 0 011.883 0l2.664 5.4 5.956.865a1.051 1.051 0 01.583 1.791l-4.311 4.2 1.018 5.933a1.049 1.049 0 01-1.036 1.227zm-12.972-11.381l3.182 3.1a1.05 1.05 0 01.3.929l-.752 4.381 3.934-2.069a1.053 1.053 0 01.978 0l3.933 2.069-.751-4.381a1.05 1.05 0 01.3-.929l3.183-3.1-4.4-.639a1.052 1.052 0 01-.791-.574l-1.966-3.985-1.967 3.985a1.051 1.051 0 01-.791.574z"
            data-name="Path 2574"
            transform="translate(-732.412 -58.851)"></path>
        </g>
        <g data-name="Group 3058" transform="translate(19.475 45.129)">
          <path
            fill="#374adb"
            d="M772.283 87.447h-40.547a1.05 1.05 0 010-2.1h40.547a1.05 1.05 0 010 2.1z"
            data-name="Path 2575"
            transform="translate(-730.686 -85.347)"></path>
        </g>
      </g>
    </svg>
  );
}
