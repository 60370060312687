import { styled } from '@b7hio/007/src/stitches.config';
import { colorsHsla } from '@b7hio/007/src/theme/colors';
import { useGetDailyDeposits } from '@b7hio/api-lib/src/ops-portal';
import { useTranslation } from 'next-i18next';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import type { TooltipProps } from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

export type ChartDataType = {
  readonly data: string;
  readonly amount: number;
};

const amountFormatter = (num: number) => {
  return Intl.NumberFormat('en-GB', {notation: 'compact'}).format(num)
};

type TooltipWrapperProps = {
  readonly color: string | undefined;
  readonly value: string;
};

const TooltipWrapper = ({ color, value }: TooltipWrapperProps) => {
  const TooltipWrapperStyle = styled('div', {
    padding: '$3',
    borderRadius: '$2',
    background: '$paperBg',
    textAlign: 'center',
    fontWeight: '$2',
    fontSize: '$1',
    position: 'relative',
    marginBottom: '30px',
    border: `1px solid ${color}`,
  });
  return <TooltipWrapperStyle>{value}</TooltipWrapperStyle>;
};

interface CustomizedDotProps {
  readonly cx?: number;
  readonly cy?: number;
  readonly color: string;
}

const CustomizedDot = ({ cx, cy, color }: CustomizedDotProps): JSX.Element => {
  return (
    <circle
      cx={cx}
      cy={cy}
      r={5}
      fill="white"
      stroke={color}
      strokeWidth={4}
      style={{
        filter: `drop-shadow(0px 0px 5px ${color})`,
      }}></circle>
  );
};

const CustomTooltip = ({ payload }: TooltipProps<ValueType, NameType>) => {
  const { t } = useTranslation(['common']);
  return (
    <>
      {payload?.map((item, index) => (
        <TooltipWrapper
          key={index}
          color={item.color}
          value={
            'Total deposits: ' +
            t('common:currency', {
              value: {
                currency: 'GBP',
                amount: item.value,
              },
            })
          }
        />
      ))}
    </>
  );
};

const tickFormatter = (value: number) => {
  return `${amountFormatter(value)}`;
};

export const DepositsLineChart = () => {
  const { t } = useTranslation(['common']);

  const currentDate = new Date();
  const pastDate = new Date(currentDate);
  pastDate.setMonth(currentDate.getMonth() - 3);

  const data = useGetDailyDeposits({
    dateTo: currentDate.toISOString().split('T')[0],
    dateFrom: pastDate.toISOString().split('T')[0],
  }).data;

  return (
    <ResponsiveContainer aspect={1.5}>
      <LineChart
        data={data}
        margin={{
          top: 10,
          right: 5,
          left: 35,
          bottom: 20,
        }}>
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey={'date'}
          type="category"
          axisLine={false}
          tickMargin={10}
        />
        <YAxis
          dataKey={'totalDeposits'}
          orientation="right"
          axisLine={false}
          tickLine={false}
          tickFormatter={tickFormatter}
        />
        <Line
          dataKey="totalDeposits"
          stroke={colorsHsla('ultramarineBlue')}
          dot={{ r: 1 }}
          strokeWidth={4}
          activeDot={<CustomizedDot color={colorsHsla('ultramarineBlue')} />}
        />
        <Tooltip content={<CustomTooltip />} />
      </LineChart>
    </ResponsiveContainer>
  );
};
