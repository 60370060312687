import React from 'react';

export function TopBanks() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="77.623"
      height="77.623"
      data-name="Group 3313"
      viewBox="0 0 77.623 77.623">
      <g data-name="Group 3066">
        <g data-name="Group 2994">
          <g data-name="Ellipse 28-3">
            <ellipse
              cx="38.811"
              cy="38.811"
              fill="#fff"
              data-name="Ellipse 32"
              rx="38.811"
              ry="38.811"></ellipse>
          </g>
        </g>
        <g data-name="Group 3035" transform="translate(23.061 42.726)">
          <path
            fill="#3b49d3"
            d="M0 0H2.1V15.893H0z"
            data-name="Rectangle 1704"></path>
        </g>
        <g data-name="Group 3036" transform="translate(27.962 42.726)">
          <path
            fill="#3b49d3"
            d="M0 0H2.1V15.893H0z"
            data-name="Rectangle 1705"></path>
        </g>
        <g data-name="Group 3037" transform="translate(35.234 42.726)">
          <path
            fill="#3b49d3"
            d="M0 0H2.1V15.893H0z"
            data-name="Rectangle 1706"></path>
        </g>
        <g data-name="Group 3038" transform="translate(40.135 42.726)">
          <path
            fill="#3b49d3"
            d="M0 0H2.1V15.893H0z"
            data-name="Rectangle 1707"></path>
        </g>
        <g data-name="Group 3039" transform="translate(47.407 42.726)">
          <path
            fill="#3b49d3"
            d="M0 0H2.1V15.893H0z"
            data-name="Rectangle 1708"></path>
        </g>
        <g data-name="Group 3040" transform="translate(52.308 42.726)">
          <path
            fill="#3b49d3"
            d="M0 0H2.1V15.893H0z"
            data-name="Rectangle 1709"></path>
        </g>
        <g data-name="Group 3045" transform="translate(18.778 58.153)">
          <g data-name="Group 3042">
            <g data-name="Group 3041">
              <path
                fill="#3b49d3"
                d="M646.665 109.694h-20.06v-3.7a3.354 3.354 0 01.686-2.426 2.669 2.669 0 012.1-.875h17.45v2.1h-17.45a.683.683 0 00-.518.155 1.338 1.338 0 00-.179.858l.008.132v1.657h17.96z"
                data-name="Path 2562"
                transform="translate(-626.59 -102.693)"></path>
            </g>
          </g>
          <g data-name="Group 3044" transform="translate(19.194)">
            <g data-name="Group 3043">
              <path
                fill="#3b49d3"
                d="M669.682 109.694h-20.061v-2.1h17.961l.008-1.8a1.331 1.331 0 00-.18-.85.682.682 0 00-.518-.155h-17.448v-2.1h17.449a2.669 2.669 0 012.1.874 3.356 3.356 0 01.688 2.426z"
                data-name="Path 2563"
                transform="translate(-649.444 -102.693)"></path>
            </g>
          </g>
        </g>
        <g data-name="Group 3046" transform="translate(18.793 26.32)">
          <path
            fill="#3b49d3"
            d="M664.99 81.662h-37.332a1.05 1.05 0 01-1.05-1.05v-4.121a1.047 1.047 0 01.533-.914l18.826-10.649a1.04 1.04 0 011.04 0l18.506 10.649a1.047 1.047 0 01.527.91v4.121a1.05 1.05 0 01-1.05 1.054zm-36.282-2.1h35.232V77.1l-17.46-10.049L628.708 77.1z"
            data-name="Path 2564"
            transform="translate(-626.608 -64.792)"></path>
        </g>
        <g data-name="Group 3047" transform="translate(35.164 31.972)">
          <path
            fill="#3b49d3"
            d="M649.649 78.619a3.549 3.549 0 113.551-3.549 3.553 3.553 0 01-3.551 3.549zm0-5a1.449 1.449 0 101.448 1.45 1.451 1.451 0 00-1.448-1.448z"
            data-name="Path 2565"
            transform="translate(-646.101 -71.521)"></path>
        </g>
        <g data-name="Group 3048" transform="translate(27.954 5.91)">
          <path
            fill="#3b49d3"
            d="M642.2 60.668a1.051 1.051 0 01-1.035-1.227l1.029-6-4.361-4.241a1.05 1.05 0 01.581-1.791l6.022-.875 2.692-5.456a1.05 1.05 0 011.884 0l2.693 5.456 6.022.875a1.05 1.05 0 01.581 1.791l-4.357 4.246 1.029 6a1.05 1.05 0 01-1.523 1.106l-5.385-2.831-5.385 2.831a1.05 1.05 0 01-.487.116zm5.874-5.188a1.059 1.059 0 01.489.12l3.99 2.1-.762-4.445a1.051 1.051 0 01.3-.929l3.228-3.146-4.462-.649a1.052 1.052 0 01-.791-.574l-1.995-4.043-1.995 4.043a1.052 1.052 0 01-.791.574l-4.462.649 3.228 3.146a1.05 1.05 0 01.3.929l-.762 4.445 3.989-2.1a1.06 1.06 0 01.493-.119z"
            data-name="Path 2566"
            transform="translate(-637.516 -40.49)"></path>
        </g>
      </g>
    </svg>
  );
}
