import { StatisticsEntityResponse } from '@b7hio/api-lib/src/gen/ops-portal-api/models/statisticsEntityResponse';
import BlockOutlined from '@mui/icons-material/BlockOutlined';
import { Box, Divider, useTheme, Link, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { OverviewComponents } from '../Overview/Overview';

interface Props {
  readonly topTimePeriod: readonly string[];
  readonly topLogos: readonly React.ReactNode[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly logo: any;
  readonly header: string;
  readonly subHeader: string;
  readonly type: 'banks' | 'platforms';
  readonly data: readonly StatisticsEntityResponse[];
}

export const TopCharts = ({
  topLogos,
  logo,
  header,
  subHeader,
  data,
  type,
}: Props): JSX.Element => {
  const topFive = data.slice(0, 5);

  return (
    <>
      <OverviewComponents
        logo={logo}
        header={header}
        subHeader={subHeader}
        isTopChartHeader={true}
      />
      <Divider />
      {topFive.length > 0 ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            flexGrow: 1,
            justifyContent: 'space-between',
            py: 2,
          }}>
          {topFive.map((props, idx) => (
            <React.Fragment key={props.name}>
              {idx !== 0 && <Divider />}
              <TopChartListItem
                uid={props.uid}
                logo={topLogos[idx]}
                name={props.name}
                type={type}
                depositValue={props.deposit}
              />
            </React.Fragment>
          ))}
        </Box>
      ) : (
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <BlockOutlined color="disabled" />
        </Box>
      )}
    </>
  );
};
interface TopChartListItem {
  readonly uid: string;
  readonly name: string;
  readonly logo: React.ReactNode;
  readonly depositValue: number;
  readonly type: 'banks' | 'products' | 'platforms';
}

const TopChartListItem: React.FC<TopChartListItem> = ({
  uid,
  name,
  logo,
  depositValue,
  type,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['common']);
  return (
    <Link color="inherit" href={`/${type}/${uid}`} underline="none">
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'min-content 300px',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 1,
          p: 1,
        }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>{logo}</Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'right',
          }}>
          <Typography
            variant="body1"
            sx={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}>
            {name}
          </Typography>
          <Box
            sx={{
              textAlign: 'right',
              color: theme.palette.primary.main,
            }}>
            {t('common:currency', {
              value: {
                amount: depositValue,
                currency: 'GBP',
              },
            })}
          </Box>
        </Box>
      </Box>
    </Link>
  );
};
