import { useGetStatistics } from '@b7hio/api-lib/src/ops-portal';
import { LoadingSpinner } from '@b7hio/core-lib/src';
import AccountBalanceOutlined from '@mui/icons-material/AccountBalanceOutlined';
import MoneyOutlined from '@mui/icons-material/MoneyOutlined';
import StoreOutlined from '@mui/icons-material/StoreOutlined';
import { Box, Paper, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { NextSeo } from 'next-seo';
import type React from 'react';
import { PageHeader } from '../../../../components/PageHeader/PageHeader';
import { Overview, TopCharts, TopProductsChart } from '../../components';
import { TopBanks, TopPlatforms, TopProducts } from '../../icons';
import { DepositsLineChart } from '../graph/DepositsLineChart';

interface Props {
  readonly portal: 'ops' | 'banks';
}

export const HomePage = ({ portal }: Props): JSX.Element => {
  const { t } = useTranslation(['shell', 'common', 'home']);
  const theme = useTheme();
  const { data, isLoading, error } = useGetStatistics({ currencyCode: 'GBP' });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error || !data) {
    return (
      <>
        There was an unfortunate error in which this data could not be located
        or returned.
      </>
    );
  }

  const topBanks = data.topBanks || []; // todo: this _can_ be null
  const topPlatforms = data.topPlatforms || []; // todo: this _can_ be null
  const topProducts = data.topProducts || []; // todo: this _can_ be null
  const totalBanks = data.totalNumberOfBanks;
  const totalProducts = data.totalNumberOfProducts;
  const totalPlatforms = data.totalNumberOfPlatforms;
  const totalDeposits = data.totalValueOfDeposits;

  return (
    <>
      <NextSeo
        title={t('home:page.title')}
        description="See https://github.com/b7hio/exchange-ui"
      />
      <PageHeader title={t('home:page.title')} />
      <Box
        sx={{
          width: '100%',
          display: 'grid',
          gridTemplateAreas: {
            sm: `"overview" "topProducts" "topPlatforms" "topBanks" "deposits"`,
            md: `"overview topProducts" "topPlatforms topBanks" "deposits deposits"`,
            lg: `"overview topProducts topPlatforms" "topBanks deposits deposits"`,
          },
          gridTemplateColumns: {
            sm: '1fr',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(4, 1fr)',
          },
          gridTemplateRows: {
            sm: '1fr',
            md: 'repeat(2, min-content)',
            lg: 'repeat(1, 500px)',
          },
          gridGap: (th) => th.spacing(2),
        }}>
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gridArea: 'overview',
            p: 1,
          }}>
          <Overview
            totalBanks={totalBanks}
            totalProducts={totalProducts}
            totalPlatforms={totalPlatforms}
            totalDeposits={totalDeposits}
            portal={portal}
          />
        </Paper>
        <Paper
          sx={{
            gridArea: 'topProducts',
            p: 1,
            display: 'flex',
            flexDirection: 'column',
          }}>
          <TopProductsChart
            data={topProducts}
            logo={<TopProducts />}
            header={t('home:topProducts.header')}
            subHeader={t('home:topProducts.subHeader')}
            topTimePeriods={topProducts.map((v) =>
              v.period ? `${v.period}M` : t('home:topProducts.call')
            )}
          />
        </Paper>
        <Paper
          sx={{
            gridArea: 'topPlatforms',
            p: 1,
            display: 'flex',
            flexDirection: 'column',
          }}>
          <TopCharts
            logo={<TopPlatforms />}
            data={topPlatforms}
            type="platforms"
            header={t('home:topPlatforms.header')}
            subHeader={t('home:topPlatforms.subHeader')}
            topTimePeriod={['30D', '3M', '1Y', '2Y', '5Y']}
            topLogos={[
              <StoreOutlined sx={{ color: theme.palette.primary.main }} />,
              <StoreOutlined sx={{ color: theme.palette.primary.main }} />,
              <StoreOutlined sx={{ color: theme.palette.primary.main }} />,
              <StoreOutlined sx={{ color: theme.palette.primary.main }} />,
              <StoreOutlined sx={{ color: theme.palette.primary.main }} />,
            ]}
          />
        </Paper>
        {portal === 'ops' && (
          <>
            <Paper
              sx={{
                gridArea: 'topBanks',
                p: 1,
                display: 'flex',
                flexDirection: 'column',
              }}>
              <TopCharts
                logo={<TopBanks />}
                data={topBanks}
                type="banks"
                header={t('home:topBanks.header')}
                subHeader={t('home:topBanks.subHeader')}
                topTimePeriod={['30D', '3M', '1Y', '2Y', '5Y']}
                topLogos={[
                  <AccountBalanceOutlined
                    sx={{ color: theme.palette.primary.main }}
                  />,
                  <AccountBalanceOutlined
                    sx={{ color: theme.palette.primary.main }}
                  />,
                  <AccountBalanceOutlined
                    sx={{ color: theme.palette.primary.main }}
                  />,
                  <AccountBalanceOutlined
                    sx={{ color: theme.palette.primary.main }}
                  />,
                  <AccountBalanceOutlined
                    sx={{ color: theme.palette.primary.main }}
                  />,
                ]}
              />
            </Paper>
            <Paper
              sx={{
                gridArea: 'deposits',
                display: 'flex',
                flexDirection: 'column',
              }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  px: 1,
                  py: 2,
                  color: 'primary.main',
                }}>
                <MoneyOutlined />
                <Typography variant="h6">Deposits</Typography>
              </Box>
              <DepositsLineChart />
            </Paper>
          </>
        )}
      </Box>
    </>
  );
};
